
import { Vue, Component } from 'vue-property-decorator'
import http from '@/api/http'

@Component
export default class Medicine extends Vue {
  private data: any = {}
  private created () {
    http.get('/uapi/article/md/detail', {id: this.$route.query.id}).then((res) => {
      this.data = res.result
    })
  }
}
